import { Component, OnInit, Input, NgZone, ViewChild, inject } from '@angular/core';
import { MenuProvider } from '../../providers/menu.provider';
import { ModalController, NavController, IonInput } from '@ionic/angular';
import { ItemPage } from '../item/item.page';
import { Subject } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { SearchProvider } from '../../providers/search.provider';
import { SpeechRecognition, SpeechRecognitionListeningOptions } from '@awesome-cordova-plugins/speech-recognition/ngx';
import { DeviceProvider } from '../../providers/device.provider';
import { ToastProvider } from '../../providers/toast.provider';
import { UriProvider } from '../../providers/uri.provider';
import { CartProvider } from '../../providers/cart.provider';

@Component({
    selector: 'app-menu',
    templateUrl: './browse.page.html',
    styleUrls: ['./browse.page.scss'],
    standalone: false
})
export class BrowsePage implements OnInit {

  @ViewChild('searchInput') searchInput: IonInput;

  @Input() voiceSearch: boolean;

  public results = [];
  public matches = '';
  public progress = 0;
  public waitTime = 5000;
  public chunk = 100;
  public runId = 0;
  public progressLock = 0;
  public parseDevice: Parse.Object;
  public speechLanguage: string;
  public search = '';
  public imagesEnabled: boolean;
  public tapAddToCartEnabled: boolean;

  private searchSubject: Subject<string> = new Subject();

  constructor(
    public menuProvider: MenuProvider,
    private modalCtrl: ModalController,
    private navCtrl: NavController,
    private searchProvider: SearchProvider,
    private speechRecognition: SpeechRecognition,
    private zone: NgZone,
    private deviceProvider: DeviceProvider,
    private toastProvider: ToastProvider,
    private uriProvider: UriProvider,
    private cartProvider: CartProvider
  ) {
    this.searchSubject.pipe(debounceTime(500))
      .pipe(switchMap(val => {
        if (val.length >= 3) {
          this.searchProvider.getSearchResults(val);
        }
        return [];
      }))
      .subscribe(results => { });

    this.searchProvider.resultsUpdated.subscribe(results => {
      this.results = results;
    });
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    await this.deviceInit();
    if (this.voiceSearch) {
      this.startListening();
    }
  }

  ionViewDidEnter() {
    this.searchInput.setFocus();
  }

  /**
   * 
   * Check Parse device settings to see which browsing features are true
   */
  async deviceInit() {
    const parseDevice = await this.deviceProvider.getCurrent('browse page :: deviceInit');
    if (parseDevice) {
      this.parseDevice = parseDevice;
      this.speechLanguage = this.parseDevice.get('speechLanguage');
      this.imagesEnabled = this.parseDevice.get('imagesEnabled');
      this.tapAddToCartEnabled = this.parseDevice.get('tapAddToCartEnabled');
    } else {
      this.toastProvider.temporary('Unable to connect');
    }
  }

  next(
    index: number,
    hasChild: boolean,
    list: any
  ) {
    this.menuProvider.next(index, hasChild);
    if (!hasChild) {
      this.closeBrowseModal();
    }
    if (list) {
      list.closeSlidingItems();
    }
  }

  async closeBrowseModal() {
    if (this.voiceSearch) {
      await this.stopListening();
      this.progressLock = -1;
    }
    this.modalCtrl.dismiss();
  }

  open(item: object) {
    this.closeBrowseModal()
      .then(() => {
        this.getModal(item);
      });
  }

  async getModal(item: object) {
    const modal = await this.modalCtrl.create({
      component: ItemPage,
      componentProps: {
        item: item
      }
    });
    modal.present();
  }

  outputItem(item: Parse.Object): string {
    let name = item.get('name');
    const escapedSearch = this.search.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const regEx = new RegExp(escapedSearch, 'ig');

    name = name.replace(regEx, match => {
      return '<strong>' + match + '<\/strong>';
    })

    return name.replace(' ', '&nbsp;');
  }

  searchInputChange(value: string, voice?: boolean) {
    this.searchSubject.next(value);
    if (voice) {
      this.runId++;
      this.progressCounter(this.waitTime, 0, this.runId);
    }
  }

  searchSubmit() {
    if (this.results.length > 0) {
      this.navCtrl.navigateRoot('view/search/' + this.search);
      this.closeBrowseModal();
    }
  }

  getImageUrl(item: any): string {
    if (item.image) {
      return this.uriProvider.forceHttps(item.image);
    }
    return item.get('image')
      ? this.uriProvider.forceHttps(item.get('image').url)
      : '';
  }

  startListening() {
    this.matches = '';
    const options: SpeechRecognitionListeningOptions = {
      language: this.speechLanguage,
      matches: 1,
      showPartial: true
    };
    this.speechRecognition.startListening(options).subscribe(matches => {
      this.zone.run(() => {
        this.matches = matches[0];
        this.searchInputChange(this.matches, true);
      });
    });
  }

  async stopListening() {
    await this.speechRecognition.stopListening();
  }

  async progressCounter(timeLeft: number, totalTime: number, id: number) {
    if (this.progressLock === 0) {
      this.progressLock = id;
    }
    if (this.progressLock === id && timeLeft > 0) {
      await this.removeChunk(timeLeft, totalTime, id);
    }
    if (this.progressLock === id && timeLeft === 0) {
      this.progressLock = 0;
      await this.stopListening();
      this.searchSubmit();
    }
  }

  removeChunk(timeLeft: number, totalTime: number, id: number) {
    setTimeout(() => {
      timeLeft = timeLeft - this.chunk;
      totalTime = totalTime + this.chunk;
      this.progress = totalTime / this.waitTime;
      this.progressCounter(timeLeft, totalTime, id);
    }, this.chunk);
  }

  async resetVoiceSearch() {
    await this.stopListening();
    this.results = [];
    this.matches = '';
    this.progress = 0;
    this.runId = 0;
    this.progressLock = 0;
    this.startListening();
  }

  /**
   * 
   * Decide whether to add to cart or open the item modal
   */
  handleTap(item: Parse.Object) {
    const isSimple: boolean = (item.get('type') === 'simple');
    if (this.tapAddToCartEnabled && isSimple) {
      this.addToCart(item);
    } else {
      this.open(item);
    }
  }

  /**
   * 
   * Add to cart via the cart provider
   */
  addToCart(item: Parse.Object) {
    this.cartProvider.add(item, 1);
  }
}
