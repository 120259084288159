import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Idle } from '@ng-idle/core';

@Component({
    selector: 'app-timeout',
    templateUrl: './timeout.page.html',
    styleUrls: ['./timeout.page.scss'],
    standalone: false
})
export class TimeoutPage implements OnInit {
  @Input() timeoutTime: number | false;
  @Input() idle: Idle

  public progress = 0;
  public timeoutLength: number;
  public message: string;

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {
    this.progress = 0;

    if (this.timeoutTime) {
      this.timeoutLength = this.timeoutTime;

      this.message = 'You will be timed out in ' + this.timeoutLength + ' seconds!';

      this.idle.onTimeoutWarning.subscribe((countdown) => {
        this.message = 'You will be timed out in ' + countdown + ' seconds!';
        this.progress = 1 - (countdown / this.timeoutLength);
      });

      this.idle.onTimeout.subscribe(() => {
        this.closeModal();
      });
    }
  }

  async logout() {
    this.idle.timeout();
    this.modalCtrl.dismiss();
  }

  async closeModal() {
    const modal = await this.modalCtrl.getTop();

    if (modal) {
      this.modalCtrl.dismiss();
    }
  }
}
