import { Component, NgZone, Input, OnInit } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { BlockProvider } from '../../providers/block.provider';
import { Block } from '../../interfaces/block';
import { DeviceProvider } from '../../providers/device.provider';
import { CameraResultType, CameraSource, Camera } from '@capacitor/camera';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { MenuProvider } from '../../providers/menu.provider';
import { ToastProvider } from '../../providers/toast.provider';
import { ErrorProvider } from '../../providers/error.provider';

@Component({
    selector: 'app-block',
    templateUrl: './block.page.html',
    styleUrls: ['./block.page.scss'],
    standalone: false
})

export class BlockPage implements OnInit {

  @Input() editBlock;

  private device: Parse.Object;

  public block: Block;
  public parseBlock: Parse.Object;
  public deviceOrientation: string;
  public targetMode = false;
  private retryCount = 0;

  constructor(
    public menuProvider: MenuProvider,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private zone: NgZone,
    private blockProvider: BlockProvider,
    private deviceProvider: DeviceProvider,
    private orientation: ScreenOrientation,
    private errorProvider: ErrorProvider,
    private toastProvider: ToastProvider
  ) { }

  ngOnInit() {
    this.init();
  }

  init() {
    this.deviceOrientation = this.getOrientationString(this.orientation.type);
    this.initBlock(this.editBlock);
    this.initDevice();
  }

  async initDevice() {
    const parseDevice = await this.deviceProvider.getCurrent('block page :: initDevice');
    const callbacks = {
      main: {
        callback: this.initDevice.bind(this)
      }
    };

    if (parseDevice) {
      this.device = parseDevice;

      if (this.retryCount > 0) {
        this.toastProvider.temporary('Success');
      }

      this.retryCount = 0;
    } else {
      this.errorProvider.alert(callbacks, 'Unable to connect');
    }

    this.retryCount++;

    return;
  }

  async initBlock(block?: any) {
    if (block) {
      await this.zone.run(() => {
        this.block = {
          image: block.get('image'),
          showImage: block.get('showImage'),
          subtitle: block.get('subtitle'),
          title: block.get('title'),
          invert: block.get('invert'),
          target: block.get('target'),
          backgroundColour: block.get('backgroundColour'),
          bgEnabled: block.get('bgEnabled'),
          mandatory: block.get('mandatory')
        };
      });

      this.parseBlock = block;
    } else {
      this.block = {
        image: './assets/images/mdl-logo.png',
        showImage: true,
        subtitle: 'Subtitle text',
        title: 'Title text',
        invert: false,
        target: '.',
        backgroundColour: '',
        mandatory: false,
        bgEnabled: false
      };
    }
  }

  async closeBlockModal(block?: Parse.Object) {
    this.modalCtrl.dismiss(block);
  }

  async imagePick() {
    const image = await this.getImage();
    const imageString = image.base64String;
    this.block.image = 'data:image/jpeg;base64,' + imageString;
  }

  async getImage() {
    return await Camera.getPhoto({
      quality: 80,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      source: CameraSource.Photos,
      width: 500
    });
  }

  async deleteImage() {
    this.block.image = './assets/images/mdl-logo.png';
  }

  /**
   * @param type Uses the parse column name
   * @param label Optional parameter to add a real label to the placeholder & title
   */
  async updateField(type: string, label?: string) {

    // Check if label has been defined, otherwise use type
    if (!label) {
      label = type;
    }

    // Create modal
    const alert = await this.alertController.create({
      header: 'Please enter a ' + label,
      inputs: [{ name: 'input', type: 'text', placeholder: label }],
      buttons: [{ text: 'Cancel', role: 'cancel' },
      { text: 'Save', handler: data => this.saveInput(type, data.input) }]
    });

    // Show modal
    alert.present();
  }

  saveInput(
    type: string,
    input: string
  ) {
    this.zone.run(async () => {
      this.block[type] = input;
    });
  }

  async save() {
    if (this.editBlock) {
      await this.blockProvider.update(this.parseBlock, this.block);
      this.closeBlockModal();
    } else {
      const block = await this.blockProvider.set(this.block, this.device);
      this.closeBlockModal(block);
    }
  }

  getOrientationString(str: string) {
    const result: string[] = str.split('-');
    
    return result[0];
  }

  toggleTarget() {
    this.targetMode = !this.targetMode;
  }

  next(
    index: number,
    hasChild: boolean
  ) {
    if (!hasChild) {
      this.block.target = this.menuProvider.next(index, hasChild, true);
      this.targetMode = false;
    } else {
      this.menuProvider.next(index, hasChild);
    }
  }

}
