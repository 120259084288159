import { Component, HostListener } from '@angular/core';
import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';
import { IdleService } from './services/idle.service';
import { register } from 'swiper/element/bundle';
import { AuthenticationProvider } from './providers/authentication.provider';

register();

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    standalone: false
})
export class AppComponent {
  constructor(
    private idleService: IdleService,
    private authenticationProvider: AuthenticationProvider
  ) {
    this.initializeApp();
  }

  @HostListener('window:beforeunload', ['$event'])
  async unloadHandler(event: Event) {
    let activeSessions = localStorage.getItem('activeSessions');

    if (Number(activeSessions) < 2) {
      localStorage.removeItem('activeSessions');
      await this.authenticationProvider.logout();
    } else {
      const count = Number(activeSessions) - 1;
      localStorage.setItem('activeSessions', count.toString());
    }
  }

  async initializeApp() {
    this.idleService.run();
    const device = await Device.getInfo();

    let activeSessions = localStorage.getItem('activeSessions');

    if (activeSessions) {
      const count = Number(activeSessions) + 1;
      localStorage.setItem('activeSessions', count.toString());
    } else {
      localStorage.setItem('activeSessions', '1');
    }

    if (device.platform !== 'web') {
      SplashScreen.hide();
    }
  }
}
