import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Callbacks } from '../interfaces/callbacks';
import { ToastProvider } from './toast.provider';
import { CallbackObject } from '../interfaces/callbackObject';

@Injectable({
  providedIn: 'root'
})
export class ErrorProvider {

  constructor(
    private alertController: AlertController,
    private toastProvider: ToastProvider
  ) {
  }

  async alert(callbacks: Callbacks, message?: string, subMessage?: string) {
    message = (!message)
      ? 'Unable to complete last action'
      : message;

    callbacks.main.label = (!callbacks.main.label)
      ? 'Retry'
      : callbacks.main.label;

    const cancelCallback = (callbacks.cancel)
      ? callbacks.cancel
      : this.getDefaultCancel();

    const alert = await this.alertController.create({
      header: message,
      message: subMessage,
      buttons: [
        {
          text: cancelCallback.label,
          role: 'cancel',
          handler: () => cancelCallback.callback(...cancelCallback.params || [])
        },
        {
          text: callbacks.main.label,
          handler: () => {
            if (callbacks.main.params) {
              callbacks.main.callback(...callbacks.main.params);
            } else {
              callbacks.main.callback();
            }
          }
        }
      ]
    });

    alert.present();
  }

  getDefaultCancel(): CallbackObject {
    return {
      callback: this.toastProvider.temporary.bind(this.toastProvider),
      label: 'Cancel',
      params: ['Cancelled']
    };
  }
}
