import { Component, OnInit, Input } from '@angular/core';
import { ModalController, ToastController, LoadingController } from '@ionic/angular';
import { CurrencyProvider } from '../../providers/currency.provider';
import { CartProvider } from '../../providers/cart.provider';
import { ParseProvider } from '../../providers/parse.provider';
import * as JsSearch from 'js-search';
import * as Parse from 'parse';
import { UriProvider } from '../../providers/uri.provider';
import { DeviceProvider } from '../../providers/device.provider';
import { ToastProvider } from '../../providers/toast.provider';
import { Quantity } from '../../interfaces/quantity';

interface ItemShop {
}

interface Select {
  code: string;
  label: string;
  position: number;
  id: number;
}

interface ConfigurableAttributeValue {
  attribute: JSON;
  label: string;
  position: number;
  enabled: boolean;
  id: number;
}

@Component({
    selector: 'app-item',
    templateUrl: './item.page.html',
    styleUrls: ['./item.page.scss'],
    standalone: false
})

export class ItemPage implements OnInit {

  @Input() item: Parse.Object;

  public isConfigurable = false;
  public qty = 1;
  public quantities: Array<Quantity> = [];
  public showValidation = false;
  public selects: Array<any> = [];
  public codes: Array<string> = [];
  public values: any = {};
  public gallery: Array<string> = [];
  public inlineImage: boolean;
  public parseDevice: Parse.Object;

  private itemShop: ItemShop = {};
  private children: any = [];
  private search: any;

  constructor(
    public currencyProvider: CurrencyProvider,
    private modalCtrl: ModalController,
    private cartProvider: CartProvider,
    private toastController: ToastController,
    private loadingController: LoadingController,
    private parseProvider: ParseProvider,
    private uriProvider: UriProvider,
    private toastProvider: ToastProvider,
    private deviceProvider: DeviceProvider
  ) {
  }

  ngOnInit() {
    this.qty = 1;
    this.initItem(this.item);
  }

  /**
   * Check Parse device settings to see which browsing features are true
   */
  async deviceInit() {
    const parseDevice = await this.deviceProvider.getCurrent('item page :: deviceInit');
    if (parseDevice) {
      this.parseDevice = parseDevice;
      this.inlineImage = await this.parseDevice.get('inlineImage');
    } else {
      this.toastProvider.temporary('Unable to connect');
    }
  }


  initItem(item: Parse.Object) {
    if (item.get('type') === 'configurable') {
      this.isConfigurable = true;
      this.presentLoading('Loading item')
        .then(() => {
          this.initConfigurableAttributes(item);
        });
    }
    this.initGallery(item);
    this.initQuantities(20);
    this.deviceInit();
  }

  async presentLoading(message: string) {
    const loading = await this.loadingController.create({
      message: message
    });
    return loading.present();
  }

  // TODO :: Refactor, tidy into separate functions
  initConfigurableAttributes(item: Parse.Object) {
    this.search = new JsSearch.Search('itemId');
    const configurableAttributes = item.get('configurableAttributes');

    configurableAttributes.forEach(attribute => {
      const code = attribute.attribute_code;
      this.search.addIndex(code);

      this.selects.push({
        code: code,
        label: attribute.frontend_label,
        position: attribute.order,
        id: attribute.attribute_id
      });

      this.codes.push(code);

      if (!this.values[code]) {
        this.values[code] = [];
      }

      attribute.values.forEach(value => {
        this.values[code].push({
          attribute: attribute,
          label: value.label,
          position: value.position,
          enabled: true,
          id: value.value_index
        });
      });
    });

    this.children = item.get('children');
    this.search.addDocuments(this.children);
    this.loadingController.dismiss();
  }

  selectedOptionsChange() {
    this.presentLoading('Updating options')
      .then(this.updateAvailableValues.bind(this));
  }

  updateAvailableValues() {
    this.selects.forEach(select => {
      const id = select.id;

      Object.keys(this.itemShop).forEach(selectedId => {
        if (selectedId !== id && this.itemShop[selectedId]) {
          const searchResults = this.search.search(this.itemShop[selectedId].label);

          this.values[select.code].forEach((value, index, values) => {
            this.checkValueValidity(select, searchResults, value, index, values);
          });
        }
      });

      this.loadingController.dismiss();
    });
  }

  checkValueValidity(
    select: Select,
    searchResults: JSON[],
    value: ConfigurableAttributeValue,
    index: number,
    values: ConfigurableAttributeValue[]
  ) {
    let found = false;
    const search = new JsSearch.Search('itemId');
    search.addIndex(select.code);
    search.addDocuments(searchResults);
    const valueResults = search.search(value.label);

    if (valueResults.length) {
      found = true;
    }

    values[index].enabled = found;
  }

  async addToCart() {
    if (this.isConfigurable) {
      const matchedChild = this.optionCombinationIsValid();
      if (matchedChild) {
        const childItem = await this.parseProvider.item.get(matchedChild.itemId);
        const imageUrl = this.getImageUrl(childItem.get('image'));

        if (this.item.get('price') != childItem.get('price')) {
          this.item['configPrice'] = childItem.get('price');
        }

        this.cartProvider.add(this.item, this.qty, imageUrl, this.itemShop);
      } else {
        this.resetItemShop();
        return;
      }
    } else {
      this.cartProvider.add(this.item, this.qty);
    }
    this.closeItemModal();
  }

  optionCombinationIsValid() {
    const itemShop = this.itemShop;
    const ids = Object.keys(itemShop);
    let matchedChild;

    if (ids.length !== this.codes.length) {
      this.toast('⛔️ Please select all options');
      this.showValidation = true;
      return;
    }

    for (let i = 0; i < this.children.length; i++) {
      const child = this.children[i];
      let matches = 0;

      ids.forEach(id => {
        const key = itemShop[id].attribute.attribute_code;
        if (child[key] === itemShop[id].label) {
          matches++;
        }
      });

      if (matches === ids.length) {
        matchedChild = child;
        break;
      }
    }
    return matchedChild;
  }

  resetItemShop() {
    this.itemShop = {};
  }

  initGallery(item: Parse.Object) {
    const gallery = item.get('gallery');

    if (gallery) {
      for (let i = 0; i < gallery.length; i++) {
        this.gallery.push(this.uriProvider.forceHttps(gallery[i].url));
      }
    }
  }

  closeItemModal() {
    this.modalCtrl.dismiss();
  }

  initQuantities(max: number) {
    let i: number;
    let data: Quantity = {};

    for (i = 0; i < max; i++) {
      data = {
        label: 'Qty ' + i,
        value: i + 1
      };
      this.quantities.push(data);
    }
  }

  convertPrice(input: string): string {
    const result: number = parseFloat(input);
    return this.currencyProvider.transform(result);
  }

  getImageUrl(image: any): string {
    return image
      ? this.uriProvider.forceHttps(image.url)
      : '';
  }

  async toast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: 2000
    });
    toast.present();
  }

}
