import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import * as Parse from "parse";

@Component({
    selector: "app-saved-cart",
    templateUrl: "./saved-cart.page.html",
    styleUrls: ["./saved-cart.page.scss"],
    standalone: false
})
export class SavedCartPage implements OnInit {
    cartInfo: Parse.Object;
    cartItems: Object = [];
    cartName: string;

    constructor(private modalController: ModalController) {}

    ngOnInit() {
        this.cartName = this.cartInfo.get("name");
        this.cartItems = Object.values(this.cartInfo.get("cartItems"));
    }

    closeModal() {
        this.modalController.dismiss();
    }
}
