import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TabToolbarComponent } from './tab-toolbar/tab-toolbar.component';
import { IonicModule } from "@ionic/angular";
import { MenuCartComponent } from './menu-cart/menu-cart.component';
import { FormsModule } from "@angular/forms";
import { KeypadComponent } from "./keypad/keypad.component";
import { NetworkNotificationComponent } from "./network-notification/network-notification.component";

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
        FormsModule
    ],
    declarations: [
        TabToolbarComponent,
        MenuCartComponent,
        KeypadComponent,
        NetworkNotificationComponent
    ],
    exports: [
        TabToolbarComponent,
        MenuCartComponent,
        KeypadComponent,
        NetworkNotificationComponent
    ]
})
export class ComponentsModule {}