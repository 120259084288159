import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ModalController, AlertController, IonInput } from '@ionic/angular';
import { ParseProvider } from '../../providers/parse.provider';
import { ToastProvider } from '../../providers/toast.provider';
import { User } from '../../interfaces/user';
import { AuthenticationProvider } from '../../providers/authentication.provider';
import { StatusService } from '../../services/status.service';

@Component({
    selector: 'app-auth',
    templateUrl: './auth.page.html',
    styleUrls: ['./auth.page.scss'],
    standalone: false
})
export class AuthPage implements OnInit {

  @ViewChild('usernameInput') usernameInput: IonInput;

  public user: User = {
    username: '',
    password: ''
  };

  @Input() type: string;
  @Input() data: any;
  @Input() title: string;

  constructor(
    private parseProvider: ParseProvider,
    private alertCtrl: AlertController,
    private authenticationProvider: AuthenticationProvider,
    private modalCtrl: ModalController,
    private statusService: StatusService
  ) { }

  ngOnInit() {
    console.log(this.type);
  }

  ionViewDidEnter() {
    setTimeout(() => this.setFocus(this.usernameInput), 300);
  }

  async userPassLogin() {
    const login = await this.authenticationProvider.login(this.user, this.type);
    if (login) {
      console.log('login attempt processed, attempting to refresh status');
      try {
        const refresh = await this.statusService.refresh();
        console.log('refresh complete', refresh);
      } catch(e) {
        console.log(e);
      }
    }
  }

  async resetPassword() {
    const alert = await this.alertCtrl.create({
      header: 'Reset Password',
      inputs: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Email'
        }
      ],
      buttons: [
        {
          text: 'Cancel',
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {}
        },
        {
          text: 'Ok',
          handler: data => this.parseProvider.user.resetPassword(data.email)
        }
      ]
    });
    await alert.present();
  }

  setFocus(
    input: IonInput
  ) {
    input.setFocus();
  }

  async logout() {
    await this.authenticationProvider.logout();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
  