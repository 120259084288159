import { Injectable } from '@angular/core';
import * as Parse from 'parse';

@Injectable({
  providedIn: 'root'
})
export class ParseReturnProvider {

  async create(details: any = {}, archive?: boolean): Promise<Parse.Object> {
    const returnOrderClass = archive ? 'ArchivedReturnOrder' : 'ReturnOrder';
    const ReturnOrder = Parse.Object.extend(returnOrderClass);
    const returnOrder = new ReturnOrder();
    returnOrder.set('order', details.order);
    returnOrder.set('items', details.items);
    returnOrder.set('payments', details.payments);
    returnOrder.set('drawer', details.drawer);

    return await returnOrder.save();
  }

  async getForOrder(id: string, archive?: boolean) {
    const orderClass = archive ? 'ArchivedOrder' : 'Order';
    const returnOrderClass = archive ? 'ArchivedReturnOrder' : 'ReturnOrder';
    const innerQuery = new Parse.Query(orderClass);
    innerQuery.equalTo('objectId', id);
    const query = new Parse.Query(returnOrderClass);
    query.matchesQuery('order', innerQuery);

    return query.find();
  }

  //Cannot use pointer object as param in cloud function so separate function
  async createCloud(id: string, archive?: boolean): Promise<Parse.Object> {
    return Parse.Cloud.run('backend-order::refund', {
      id: id,
      archive: archive
    });
  }

  async updateRefundStatus(order: Parse.Object, payments: Parse.Object[], refunds: Parse.Object[]) {
    let orderTotal = 0;
    let refundTotal = 0;

    for (const payment of payments) {
      orderTotal += payment.get('amount');
    }

    for (const refund of refunds) {
      for (const refundPayment of refund.get('payments')) {
        refundTotal += refundPayment.amount;
      }
    }

    const status = orderTotal == refundTotal ? 'Full' : 'Partial';
    order.set('refunded', status)
    await order.save();
  }

}
