import { Injectable } from '@angular/core';
import { Toast } from '@awesome-cordova-plugins/toast/ngx';
import { ToastController } from '@ionic/angular';
import { Device } from '@capacitor/device';

@Injectable({
  providedIn: 'root'
})
export class ToastProvider {

  private toasts: HTMLIonToastElement[] = [];
  private mToasts: Object[] = [];

  constructor(
    private toast: Toast,
    private toastController: ToastController
  ) {
  }

  async temporary(
    message: string,
    duration?: string
  ) {
    const device = await Device.getInfo();
    duration = (!duration) ? '2000' : duration;
    if (device.platform !== 'web') {

      this.mToasts.push({ message: message, duration: duration });

      if (this.mToasts.length === 1) {
        this.mShow();
      }

    } else {
      const toastSlice = await this.toastController.create({
        message: message,
        position: 'top',
        duration: parseInt(duration, 10)
      });

      toastSlice.onDidDismiss().then(
        () => {
          this.toasts.shift();
          if (this.toasts.length > 0) {
            this.show();
          }
        }
      )

      this.toasts.push(toastSlice);

      if (this.toasts.length === 1) {
        this.show();
      }
    }
  }

  show() {
    this.toasts[0].present();
  }

  mShow() {
    this.toast.show(this.mToasts[0]['message'], this.mToasts[0]['duration'], 'top').subscribe(
      toast => {
        if (toast && toast.event && toast.event === 'hide') {
          this.mToasts.shift();
          if (this.mToasts.length > 0) {
            this.mShow();
          }
        }
      }
    );
  }

  async showToast(
    message: string,
    duration?: number
  ) {
    if (!duration) {
      duration = 2000;
    }
    const toast = await this.toastController.create({
      message: message,
      position: 'top',
      duration: duration
    });
    toast.present();
  }
}
