import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'keypad',
    templateUrl: './keypad.component.html',
    styleUrls: ['./keypad.component.scss'],
    host: {
        '(window:keyup)': 'handleKeyUp($event)'
    },
    standalone: false
})
export class KeypadComponent {

  @Output() change: EventEmitter<string> = new EventEmitter<string>();
  @Output() reset: EventEmitter<boolean> = new EventEmitter<boolean>();
  
  @Input() keypadValue = '0.00';

  constructor() { }

  handleKeyUp(event) {
    const keyPressed = event.key;
    const validKeys = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    if (validKeys.includes(keyPressed)) {
      this.numberButton(keyPressed);
    } else {
      switch (keyPressed) {
        case 'Backspace':
          this.backspaceButton();
          break;
        case 'Enter':
          this.submitKeypad();
          break;
      }
    }
  }

  numberButton(buttonPressed: string) {
    this.appendButtonValue(buttonPressed);
  }

  appendButtonValue(value: string) {
    let number: string = this.removeDecimalPoint(this.keypadValue);
    number += value;
    this.keypadValue = this.formatKeypadValue(number);
  }

  formatKeypadValue(number: string): string {
    let cents = '';
    let dollars = '';
    const length = number.length;

    if (length > 2) {
      cents = number.substring(length - 2);
      dollars = number.substring(0, length - 2);
    } else {
      dollars = '0';
      cents = this.formatCentsValue(number);
    }

    number = dollars + '.' + cents;

    if (number.length > 4) {
      number = this.removeLeadingZero(number);
    }
    return number;
  }

  formatCentsValue(number: string): string {
    if (number.length === 1) {
      return '0' + number;
    }
    return number;
  }

  removeLeadingZero(number: string): string {
    if (number.substring(0, 1) === '0') {
      return number.substring(1, number.length);
    }
    return number;
  }

  backspaceButton() {
    const number = this.removeDecimalPoint(this.keypadValue);
    this.keypadValue = this.formatKeypadValue(number.substring(0, number.length - 1));
  }

  removeDecimalPoint(number: string): string {
    return number.replace('.', '');
  }

  resetKeypad() {
    this.keypadValue = "0.00";
    this.reset.emit(true);
  }

  submitKeypad() {
    this.change.emit(this.keypadValue);
  }
}
