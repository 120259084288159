export const environment = {
  production: true,
  parseAppId: '3116bf8850d0eb228e976a5f8f06808a',
  parseServerUrl: 'https://test-22185.nodechef.com/parse',
  currency: 'GBP',
  currencySymbol: '\u00A3',
  appId: 'mdlpos',
  project: 'demo',
  version: '3.5.0',
  published: '2025-03-03',
  firebase: {
    apiKey: "AIzaSyD6mGF9ofNsiCJxNn1bvqEwIoxncbfNR-o",
    authDomain: "mdlware-io.firebaseapp.com",
    databaseURL: "https://mdlware-io.firebaseio.com",
    projectId: "mdlware-io",
    storageBucket: "mdlware-io.firebasestorage.app",
    messagingSenderId: "115005790053",
    appId: "1:115005790053:web:0ca4ffa4d010c136",
    measurementId: "G-R3QJ2EHPDZ"
  }
};