import { Injectable } from '@angular/core';
import domtoimage from 'dom-to-image-more';
import { ErrorProvider } from './error.provider';
import { Callbacks } from '../interfaces/callbacks';

@Injectable({
  providedIn: 'root'
})
export class PrintProvider {
  constructor(
    private errorProvider: ErrorProvider
  ) {

  }

  async sendDataToPrinter(
    printLines: any[]
  ) {
    try {
      const popup = window.open();
      popup.document.write('<style>html \{ margin: 0 !important\; font-family: \'Courier New\', Courier, monospace !important\; \} .Left \{ text-align: left; \} .Right \{ text-align: right; \}</style>');

      for (let i = 0; i < printLines.length; i++) {
        const printLine = printLines[i];
        popup.document.write('<div>' + printLine + '</div>');
      }

      await this.delay(0);
      popup.document.close();
      popup.focus();
      popup.print();
      popup.close();
    } catch (error) {
      const callbacks: Callbacks = {
        main: {
          callback: this.sendDataToPrinter.bind(this),
          params: [printLines]
        }
      };

      this.errorProvider.alert(callbacks, 'Unable to print');
      console.error(error);
    }

  }

  async getDataUrl(
    componentName: string
  ): Promise<string> {
    const node = document.getElementById(componentName);
    return await domtoimage.toPng(node);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

